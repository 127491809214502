
import http from '../../http';
export function getReportList (params) {
    return http({
        url: "/api/reportStats/reimbursePage",
        method: "get",
        params:params
    })
}
export function reportDetial (params) {
    return http({
        url: "/api/reportStats/takeOff",
        method: "get",
        params:params
    })
}


export function editReport (data) {
    return http({
        url: "/api/reportStats/takeOff",
        method: "put",
        data:data
    })
}
export function discardReport (data) {
    return http({
        url: "/api/report/project",
        method: "post",
        data:data
    })
}

export function audit (data) {
    return http({
        url: "/api/base/audit",
        method: "post",
        data:data
    })
}

export function reimbursePayed (data) {
    return http({
        url: "/api/reportStats/reimbursePayed",
        method: "put",
        data:data
    })
}


