<template>
  <div>
    <!-- title 组件 -->
    <div class="content-tile">
      <div class="su-title">
        <i class="el-icon-document-copy"></i>
        <span>报销汇总表</span>
      </div>
      <div>
        <!--横等于20000显示-->
        <el-button type="primary" @click="dialogVisible = true">新建报销</el-button>
      </div>
    </div>
    <div class="dialogVisibleBox">
      <el-dialog
          title="申请报销"
          :visible.sync="dialogVisible"
          width="20%"
          :append-to-body="true">
        <el-form ref="form" label-width="80px">
          <el-form-item label="报销类型">
            <el-select v-model="reimbursementType" placeholder="请选择报销类型">
              <el-option label="差旅费用报销" value="2"></el-option>
              <el-option label="普通费用报销" value="1"></el-option>

            </el-select>
          </el-form-item>
        </el-form>


        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="jumpToCreateReimbursement">确 定</el-button>
        </span>
      </el-dialog>
    </div>

    <div>
      <el-date-picker
          v-model="timeScope"
          type="daterange"
          style="margin-bottom: 15px"
          @change="initLoad"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期">
      </el-date-picker>
    </div>

    <div style="display: flex; justify-content: space-around; margin: 0 0 20px 0; font-size: 20px">
      <div>报销合计: ￥{{all_stats}}</div>
      <div>已报销: ￥{{pay_stats}}</div>
      <div>待报销: ￥{{unpay_stats}}</div>
    </div>
    <!--addDialog 组件 -->
    <common-add-dialog
        :addDialogData="addDialogData"
        @addDialogSub="addDialogSub"

    />
    <!--table 组件 -->
    <common-table
        :tableData="tableData"
        :tableProp="tableProp"
        @pageCurrentChange="pageCurrentChange"
        @detileLeave="detileLeave"
        @payEnd="payEnd"

    />


  </div>
</template>
<script>
import {getReportList, reimbursePayed} from "../../../api/module/reimburse/reimburse_func";

export default {
  components: {},
  props: [],
  data() {
    return {
      all_stats: 0,
      pay_stats: 0,
      unpay_stats: 0,
      form: {
        dateTime: '',
      },
      reimbursementType: '1',
      dialogVisible: false,
      //table 数据源
      tableProp: {
        index: false,//是否需要有序号
        selection: false,//是否是多选框
        selectionVal: 'id',// 开启多选后对比的字段
        selectionId: [],// 开启多选后，选择哪几条
        isPage: true, //是否分页
        statusName: 'btn_status',//状态字段名 已启动未启动等状态按钮显示还是隐藏
        page: {
          current_page: 1,
          total: 0,
          size: 10,
          method: 'pageCurrentChange',
        },
        table: [{
          title: '编号',
          field: 'id',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '报销人',
          field: 'user_name',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '报销类型',
          field: 'type_name',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '关联项目',
          field: 'project_title',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '报销事由',
          field: 'reason',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '金额',
          field: 'all_price',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '创建时间',
          field: 'created_at',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 4,
        }, {
          title: '报销状态',
          field: 'is_pay_name',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '审核状态',
          field: 'status_name',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '操作',
          isWidth: false,
          width: '220px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 2,
          tableOperate: [{
            name: '查看',
            type: 'primary',
            method: 'detileLeave',
            status: '20000',
            id: 20000,
          },{
            name: '已打款',
            type: 'primary',
            method: 'payEnd',
            status: '3',
            id: 20000,
          }],
        }],
      },

      //表格数据
      tableData: [],
      //addDialog提交数据
      subData: {},
      timeScope: [],
      //addDialog数据源
      addDialogData: {
        isDialog: false,//dialog是否显示
        subMethod: 'addDialogSub',//点击确定方法名
        title: '123',// dialog 标题
        top: '50px', // dialog 离浏览器top
        width: '1500px', // dialog 长度，可以是百分比
        labelWidth: '160px', // label 长度
        //dialog 数据源
        addDialogProp: [{
          label: '项目名称',
          field: 'project_title',
          type: 2,
          show: true,
        },
          {
            label: '报销人姓名',
            field: 'user_name',
            type: 2,
            show: true,
          },
          {
            label: '报销类型',
            field: 'type',
            type: 3,
            show: true,
          },{
            label: '报销金额',
            field: 'all_price',
            type: 2,
            show: true,
          }
          ,{
            label: '报销描述',
            field: 'reason',
            type: 2,
            show: true,
          },{
            label: '物资金额',
            field: 'material_price',
            type: 2,
            show: true,
          },{
            label: '附件信息',
            field: 'attachment_json',
            type: 8,
            show: true,
          },{
            label: '报销明细',
            field: 'jsonDetail',
            type: 14,
            show: true,
          },{
            label: '物资明细',
            field: 'goods',
            type: 16,
            show: true,
          },{
            label: '不通过原因',
            field: 'no_reason',
            type: 1,
            show: true,
          }],
        //dialog 显示数据
        addDialogShowData: {
          all_price:'',
          reason:'',
          user_name:'',
          project_title:'',
          attachment_json:[],
          jsonDetail:[],
          type:1,
          material_price:'',
          goods:[],

        },
        typeProp: [
          {value: 1,label: '普通报销'},
          {value: 2,label: '差旅费报销'}
        ],
      },
    };
  },

  created() {
    // this.timeScope = new Date(new Date().toLocaleDateString()).getTime() - 31 * 24 * 3600 * 1000;
    this.timeScope = [new Date(new Date().toLocaleDateString()).getTime(), new Date(new Date().toLocaleDateString()).getTime()]
    this.initLoad();
  },
  mounted() {
  },
  methods: {
    jumpToCreateReimbursement() {
      console.log(this.reimbursementType)
      //默认普通报销
      let path = "../../staff/my_center/reimbursement/normal"
      if (this.reimbursementType == 2) {
        //差旅费报销
        path = "../../staff/my_center/reimbursement/travel"
      }
      this.$router.push(path)
    },
    //表格初始化
    initLoad() {
      this.$store.state.loading = true;
      let params = {
        page: this.tableProp.page.current_page,
        timeScope: [0, 0]
      }
      params.timeScope[0] = this.timeScope[0] / 1000
      params.timeScope[1] = this.timeScope[1] / 1000 + 24*60*60 - 1

      getReportList(params).then((result) => {
        let res = result.data;
        this.all_stats = res.stats.all_stats
        this.pay_stats = res.stats.pay_stats
        this.unpay_stats = res.stats.unpay_stats
        this.tableProp.page.current_page = res.current_page;
        this.tableProp.page.total = res.total;
        this.tableProp.page.size = res.per_page;
        for (let i = 0; i < res.data.length; i++) {
          res.data[i].project_title = "暂无相关项目"
          res.data[i].user_name = "暂无相关项目"
          if(res.data[i].project){
            res.data[i].project_title = res.data[i].project.project_title
          }
          if(res.data[i].user_info){
            res.data[i].user_name = res.data[i].user_info.name
          }
          if (res.data[i].type == 1) {
            res.data[i].type_name = "普通报销"
          } else {
            res.data[i].type_name = "差旅报销"
          }
          if (res.data[i].status == 0) {
            res.data[i].status_name = "待审核"
          } else if(res.data[i].status == 1) {
            res.data[i].status_name = "审核通过"
          } else {
            res.data[i].status_name = "审核不通过"
          }

          if (res.data[i].is_pay == 1) {
            res.data[i].is_pay_name = "报销完成"
          } else if(res.data[i].is_pay == 2) {
            res.data[i].is_pay_name = "待报销"
          }

          res.data[i].btn_status = res.data[i].status + res.data[i].is_pay
        }
        this.tableData = res.data
      })
    },
    //tabs 切换点击
    tabsClick(tab) {
      //this.tabsProp.active //tabs 当前的值
      this.tableProp.page.current_page = 1;
      this.initLoad()
      console.log(tab.name)
    },
    //tabs 点击搜索
    keyWordMethod() {
      this.tableProp.page.current_page = 1;
      this.initLoad()
      console.log(this.tabsProp.keywords);//搜索的值
    },
    //tabs 高级搜索点击确定
    highKeyWordMethod() {
      this.tableProp.page.current_page = 1;
      this.initLoad()
      console.log(this.tabsProp.heighKewWordField);//高级搜索的字段
    },

    //table 详情 按钮点击
    detileLeave(row) {
      if (row.status==2){
        //不通过，显示原因
        this.addDialogData.addDialogShowData.no_reason = row.no_reason
        this.addDialogData.addDialogProp[this.addDialogData.addDialogProp.length-1].show = true
      }else {
        this.addDialogData.addDialogShowData.no_reason = ""
        this.addDialogData.addDialogProp[this.addDialogData.addDialogProp.length-1].show = false

      }
      this.addDialogData.addDialogShowData.material_price = row.material_price
      this.addDialogData.addDialogShowData.goods = row.goods
      this.addDialogData.addDialogShowData.type = row.type
      this.addDialogData.addDialogShowData.all_price = row.all_price
      this.addDialogData.addDialogShowData.reason = row.reason
      this.addDialogData.addDialogShowData.project_title = row.project.project_title
      this.addDialogData.addDialogShowData.user_name = row.user_info.name
      this.addDialogData.addDialogShowData.attachment_json = JSON.parse(row.attachment_json)
      for (let i = 0; i < row.part.length; i++) {
        row.part[i].start_times = this.timestampToTime(row.part[i].start_time)
        row.part[i].end_times = this.timestampToTime(row.part[i].end_time)
      }
      this.addDialogData.addDialogShowData.jsonDetail = row.part

      this.addDialogData.title = '报销明细';
      this.addDialogData.isDialog = true;
    },
    //table 已打款 按钮点击
    payEnd(row) {
      this.$confirm('确定已打款吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let data = {
          id: row.id,
          is_pay: 1
        }
        reimbursePayed(data).then(()=>{
          this.$message({
            message: "操作成功！",
            type: 'success'
          });
          this.initLoad();
        })
      })

    },
    //table 分页 点击当前页
    pageCurrentChange(val) {
      this.tableProp.page.current_page = val
      this.initLoad();
    },
    // addDialog 点击确定
    addDialogSub() {
      this.addDialogData.isDialog = false;
    },
    timestampToTime(timestamp) {
      var date = new Date(timestamp * 1000);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + '-';
      var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';
      var D = date.getDate() + ' ';
      if (parseInt(D)<10){
        D="0"+D;
      }
      return Y+M+D;
    }

  },
};
</script>
<style lang="scss" scoped>
.content-tile {
  @include flex(nowrap, space-between, center);
  background: $title-bg;
  border: 1px solid $title-bg;
  padding: 8px;
  margin-bottom: 12px;

  .su-title {
    font-weight: bold;
    @include flex();

    i {
      font-size: 16px;
      color: $title-icon;
      font-weight: bold;
      margin-right: 8px;
    }
  }
}
</style>
